<template>
  <div v-if="appConfig && dataset">
    <v-row
      wrap
      no-gutters
      style="min-height: 300px"
    >
      <v-col
        cols="12"
        xs="12"
      >
        <v-text-field
          v-model="dataset.name"
          label="Model Name (what should your model be saved as?)"
          placeholder="Model Name"
          clearable
        />
      </v-col>
      <v-col
        cols="12"
        xs="12"
      >
        <v-select
          v-model="dataset.algorithm"
          item-value="code"
          item-text="name"
          :items="appConfig.algorithms"
          label="Type"
          clearable
        />
      </v-col>
      <template v-if="dataset.axis === 'build'">
        <v-col
          cols="12"
          xs="12"
        >
          <h3 class="caption">
            Prediction Date Range
          </h3>
          <date-picker
            v-model="dataset.start_date"
            placeholder="Start Date"
            value-type="format"
            class="mr-1"
          />
          <date-picker
            v-model="dataset.end_date"
            placeholder="End Date"
            value-type="format"
          />
        </v-col>
        <v-col
          cols="12"
          xs="12"
        >
          <h3 class="caption">
            Start Testing From
          </h3>
          <date-picker
            v-model="dataset.start_test_date"
            placeholder="Test the predictions on known data after this date:"
            value-type="format"
          />
        </v-col>
      </template>
      <v-col
        cols="12"
        xs="12"
      >
        <v-autocomplete
          v-model="dataset.independent_variable_names_selected_by_user"
          label="Features"
          chips
          clearable
          deletable-chips
          multiple
          small-chips
          item-text="name"
          item-value="code"
          :items="features"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        xs="12"
      >
        <v-btn
          color="primary"
          @click="emitBuild"
        >
          Build
        </v-btn>
        <!-- <v-btn class="ml-2" color="green" text> Save </v-btn> -->
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';

import ApiService from '../../services/api.service';
import { getSelectedX } from '../../lib/model';

const modelNamespace = createNamespacedHelpers('model');

export default {
  props: ['input'],
  data() {
    return {
      iModel: {
        dataset: null,
      },
      dataset: null,
      loading: false,
      appConfig: null,
      multipleValueAction: 'sum',
      missingValueAction: 'remove_values',
      features: [],
      parameters: [],
    };
  },
  mounted() {
    this.appConfig = this.$store.state.appConfig;
    if (this.input && this.input.dataset) {
      this.dataset = { ...this.input.dataset };
      if (!this.dataset.independent_variable_names_selected_by_user) {
        this.dataset.independent_variable_names_selected_by_user = [
          'day_of_year',
          'Lat best',
          'Long best',
        ];
      }
      const xs = getSelectedX(this.input.model);
      this.addXFeatures(xs);
      this.selectXFeatures(xs);
      this.setFeatures(xs);
      this.updateSavedFeatures();
      this.cleanUserSelectedVariables();
      // if (!this.dataset.list) {
      //   this.dataset.independent_variable_names_selected_by_user = ['day_of_year', 'Lat best', 'Long best'];
      // }
    }
  },
  methods: {
    ...modelNamespace.mapActions([]),
    ...modelNamespace.mapMutations(['mSetStep']),
    emitBuild() {
      this.$emit('build', this.dataset);
    },
    addXFeatures(xs) {
      const dataset = this.dataset;
      if (!dataset.xFeatures) {
        dataset.xFeatures = [];
      }
      // dataset.independent_variable_names_selected_by_user
      console.log('xs', xs);
    },
    selectXFeatures(xs) {
      // Auto select new features
      const dataset = this.dataset;
      if (!dataset.savedFeatures) {
        dataset.savedFeatures = [];
      }
      const selected = dataset.independent_variable_names_selected_by_user;
      console.log('selected', selected);
      console.log('dataset.savedFeatures', dataset.savedFeatures);
      xs.forEach((item) => {
        if (!dataset.savedFeatures.includes(item.code)) {
          selected.push(item.code);
        }
      });
    },
    setFeatures(xs) {
      const features = [...this.appConfig.variables];
      const keys = xs.map((item) => item.code);
      xs.forEach((x) => {
        features.push(x);
      });
      console.log('xs', xs);
      console.log('xs', features);
      this.features = features;
    },
    updateSavedFeatures() {
      const dataset = this.dataset;
      dataset.savedFeatures = this.features.map((item) => item.code);
    },
    cleanUserSelectedVariables() {
      const dataset = this.dataset;
      const features = this.features.map((item) => item.code);
      console.log('features', features);
      dataset.independent_variable_names_selected_by_user = [...new Set(dataset.independent_variable_names_selected_by_user.filter((item) => features.includes(item)))];
      console.log('dataset.independent_variable_names_selected_by_user', dataset.independent_variable_names_selected_by_user);
    },
    nextStep() {
      this.$emit('next', this.dataset);
    },
    async loadParameter(table, parameter) {
      try {
        parameter.key = parameter.code;
        const q = `SELECT distinct(${parameter.column}) AS value from ${table}`;
        parameter.loading = true;
        const response = await ApiService.post('/raw_sql/', { q });
        parameter.key = `${parameter.name}loaded`;
        parameter.list = response.data;
      } catch (error) {}

      parameter.loading = false;
    },
    async loadParameters(table, p) {
      const parameters = [...p];
      // const that = this;

      const requests = parameters.map((parameter) => {
        const q = `SELECT distinct(${parameter.column}) AS value from ${table}`;
        return ApiService.post('/raw_sql/', { q });
      });

      this.loading = true;
      const that = this;
      const responses = await Promise.all(requests);
      responses.forEach((response, key) => {
        // if (that.model && that.model[])
        parameters[key].list = response.data;
        parameters[key].key = `${parameters[key].name}loaded`;
      });
      this.parameters = parameters;
      this.loading = false;
    },
    datesetSelected(table_name) {
      const dataset = this.appConfig.datasets.find(
        (item) => item.table_name === table_name,
      );
      this.loadParameters(dataset.table_name, dataset.parameters);
      this.$emit('updated', this.dataset);
      console.log('dataset', dataset);
    },
  },
};
</script>
